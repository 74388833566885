import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { TakeActionLink, TakeActionButton } from '../components/buttons';
import getTwitterUsername from '../hooks/twitter';
import Columns from '../components/columns';
import IssueList from '../components/issue-list';
import { parseISO, format } from 'date-fns';
import {
  AirtableLocation,
  AirtableIssueType,
  mapLocation,
  mapIssueType,
} from '../airtable-mapping';
import Sharing from '../components/sharing';

const WIDGET_SRC = 'https://embed.joincampaignzero.org/widget.js';

const StatePage: React.FC<{
  pageContext: {
    airtableLocation: AirtableLocation;
    airtableIssueTypes: AirtableIssueType[];
  };
}> = ({ pageContext }) => {
  const allIssueTypes = pageContext.airtableIssueTypes
    .map(mapIssueType)
    .filter(type => {
      return !!type.name;
    });

  const state = mapLocation(pageContext.airtableLocation);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = WIDGET_SRC;
    script.async = true;

    setTimeout(() => {
      document.body.appendChild(script);
    }, 100);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  /**
   * Format the Repealed Date
   * @param iso
   * @returns localized time
   * @see https://date-fns.org/
   */
  function formatRepealDate(iso) {
    let date = parseISO(iso);
    return format(date, 'PP');
  }

  return (
    <Layout>
      <SEO
        title={`${state.name}`}
        ogdescription="Stop police unions from rigging the system. Take action now to help #NixThe6!"
      />
      <StatePageContainer>
        <div className="state-name">
          <h2 className={`text${state.repealed ? ' repealed' : ''}`}>
            {state.name}
          </h2>
          {state.image && <img src={state.image.url} alt="" />}
          {state.repealed && (
            <div className="repealed-date">
              Repealed: {formatRepealDate(state.repealed)}
            </div>
          )}
        </div>
        {state.contracts.map((contract, i) => {
          return (
            <section key={i} className="contract-info">
              <Columns>
                <div className="contract-details">
                  <h3>
                    {contract.name ||
                      `${state.name} ${
                        state.hasPoliceBillOfRights
                          ? 'Police Bill of Rights'
                          : 'Police Policies'
                      }`}
                  </h3>

                  {/* Polling Results */}
                  {state.pollResults.length > 0 && (
                    <div className="polling">
                      <h4>Current Polling:</h4>
                      {state.pollResults.map((pollResult, i) => (
                        <p key={i}>
                          {state.name} {pollResult.poll.description}:{' '}
                          <a
                            href="https://medium.com/@YouGovBlue/americans-support-police-reform-even-in-the-reddest-states-455b6c794e54"
                            className="statistic"
                          >
                            {pollResult.result * 100}%
                          </a>
                        </p>
                      ))}
                    </div>
                  )}

                  <div className="q-a">
                    <h4>Things to remember:</h4>
                    <ul className="ul-icon li-arrow">
                      <li>
                        {state.pollResults.length > 0
                          ? 'The majority of voters in your state support repealing this law and'
                          : 'The public supports'}{' '}
                        removing these provisions that protect officers and not
                        communities.
                      </li>
                      <li>
                        Labor unions are an important part of organizing
                        community. But police union contracts contain items that
                        protect their members and harm community members and
                        community safety.
                      </li>
                      <li>
                        Police unions have stood in the way of implementing many
                        of the changes that we fight for and deserve.
                      </li>
                    </ul>
                  </div>

                  <div className="cta-links" key="involvementUrl">
                    <a href={constants.volunteerUrl}>Get Involved Today</a>
                  </div>
                </div>
                <div className="contract-policies">
                  <h4>This law:</h4>
                  <IssueList
                    contract={contract}
                    allIssueTypes={allIssueTypes}
                  />

                  <Sharing
                    copy={{
                      download: 'View State Law',
                    }}
                    urls={{
                      download: contract.document?.url || contract.externalUrl,
                    }}
                    share={contract.issues.length > 0 ? true : false} // If there are no contracts, then hide the share buttons
                  />
                </div>
              </Columns>
            </section>
          );
        })}

        {/* Action links */}
        {state.officials.length > 0 && (
          <section className="take-action">
            <h3>Take Action</h3>
            <Columns className="details">
              <div className="framing">
                <p className="intro">
                  Police Officer Bills of Rights exist to enshrine a set of
                  protections into state law that almost guarantee that officers
                  cannot be held accountable, and limit Mayors and other leaders
                  from taking swift and necessary action when warranted.
                </p>

                <h5 className="uppercase">Here's what to say:</h5>
                <ul className="ul-icon li-arrow">
                  {/* TODO: Show this bullet point if poll indicates it is true: */}
                  {state.hasPoliceBillOfRights && (
                    <li>
                      We need to repeal this bill of rights now. There’s no need
                      to for these protections for police officers, especially
                      because they don’t serve to protect the public.
                    </li>
                  )}
                  <li>
                    This isn’t about due process. Due process exists to ensure
                    that officers are treated fairly. This law guarantees that
                    officers have a level of protection in the discipline
                    process that nearly guarantees no accountability.
                  </li>
                  <li>
                    Police unions wield too much power, already. And you, our
                    elected officials, can change this now.
                  </li>
                </ul>
              </div>
              <div className="contacts">
                <ul>
                  {state.officials.map(official => (
                    <li key={official.name}>
                      <h4>
                        {official.name}
                        {official.phone && (
                          <span>
                            <br /> Call:{' '}
                            <a
                              href={'tel:' + official.phone}
                              className="phone-link"
                            >
                              {official.phone}
                            </a>
                          </span>
                        )}
                      </h4>

                      <h5>
                        {official.roles.map(role => role.name).join(', ')}{' '}
                      </h5>

                      <div className="buttons">
                        {official.email && (
                          <TakeActionLink
                            href={
                              official.email.includes('@')
                                ? `mailto:${official.email}`
                                : official.email
                            }
                            target="_blank"
                          >
                            Email
                          </TakeActionLink>
                        )}

                        {official.twitter && (
                          <TakeActionButton
                            data-sharer="twitter"
                            data-title={
                              '@' +
                              getTwitterUsername(official.twitter) +
                              ' Stop police unions from rigging the system in favor of officers who commit misconduct. Take action now to help #NixThe6!'
                            }
                            data-url={pageUrl}
                          >
                            Tweet
                          </TakeActionButton>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Columns>
          </section>
        )}
        <section className="call-legislators">
          <Columns className="content">
            <div className="details">
              <h3>Call your own state legislators</h3>
            </div>
            <div className="widget">
              <div id="campaign-zero-widget" />
            </div>
          </Columns>
        </section>
      </StatePageContainer>
    </Layout>
  );
};

export const StatePageContainer = styled.div`
         .container {
           max-width: 1184px;
           margin: 0 auto;
         }

         .state-name {
           color: ${constants.highlightForegroundColor};
           text-align: center;
           position: relative;
           background-color: ${constants.highlightColor};
           padding: 100px 0;

           @media (max-width: 767.98px) {
             font-size: 30px;
             line-height: 104px;
           }

           .text {
             position: relative;
             z-index: 2;
             margin-bottom: 0px;
             display: inline-block;

             @media (max-width: 767.98px) {
               font-size: 48px;
             }
           }

           .text.repealed:after {
             content: '';
             display: block;
             position: absolute;
             width: 120%;
             max-width: 100vw;
             height: 6px;
             background: #fa0000;
             bottom: calc(50% - 6px);
             left: -10%;
           }

           img {
             position: absolute;
             z-index: 1;
             height: 60%;
             left: 50%;
             top: 50%;
             transform: translateX(-50%) translateY(-50%);
             opacity: 0.5;
             max-width: 90%;
           }

           .repealed-date {
             background: #fa0000;
             color: #fff;
             font-weight: bold;
             font-size: 22px;
             line-height: 28px;
             display: inline-block;
             position: absolute;
             text-transform: uppercase;
             left: calc(50% - 162.5px);
             bottom: -22px;
             text-align: center;
             padding: 0.5rem;
             max-width: 100%;
             width: 325px;
           }
         }

         .contract-info {
           background: #fff;
           color: ${constants.contentTextColor};
           padding: 60px;
           max-width: 1184px;
           margin: 0 auto;

           @media (min-width: 768px) {
             border-bottom-left-radius: 8px;
             border-bottom-right-radius: 8px;
           }

           @media (max-width: 767.98px) {
             padding: 24px 21px;
           }

           h3 {
             text-align: left;
             margin-bottom: 1em;

             @media (max-width: 767.98px) {
               font-size: 21px;
               line-height: 24px;
             }
           }

           .polling {
             margin-bottom: 50px;

             & > * {
               margin-bottom: 1em;
             }

             h4 {
               text-transform: uppercase;
             }

             p {
               font-size: 24px;
               line-height: 36px;

               @media (max-width: 767.98px) {
                 font-size: 20px;
                 line-height: 24px;
               }

               a.statistic {
                 text-decoration: none;
                 font-weight: 900;
                 color: ${constants.highlightColor};
                 transition: ${constants.colorTransition};

                 &:hover {
                   color: #dd7f1b;
                 }
               }
             }

             li {
               font-size: 21px;
               line-height: 28px;
               margin-bottom: 1em;
             }
           }

           .q-a {
             margin-bottom: 0;

             @media (max-width: 767.98px) {
               margin-bottom: 50px;
             }
           }

           .cta-links {
             display: inline-block;
             margin-top: 2rem;

             @media (max-width: 767.98px) {
               margin-top: 0;
             }

             a {
               background: ${constants.highlightForegroundColor};
               color: ${constants.highlightColor};
               font-weight: bold;
               border-radius: 8px;
               padding: 1.2em;
               display: block;
               font-size: 16px;
               text-transform: uppercase;
               text-decoration: none;
               text-align: center;
               margin-bottom: 15px;
               transition: ${constants.colorTransition};

               &:hover {
                 color: #fff;
               }
             }
           }

           .contract-policies {
             h4 {
               text-transform: uppercase;
               font-size: 21px;
               text-align: center;
               margin-bottom: 2em;

               @media (max-width: 767.98px) {
                 font-size: 14px;
                 letter-spacing: 0.25px;
                 line-height: 28px;
                 margin-bottom: 1rem;
               }
             }
           }
         }

         .take-action {
           max-width: 1184px;
           margin: 60px auto;

           @media (max-width: 1184px) {
             padding: 0 1rem;
           }

           h3 {
             text-align: center;
             text-transform: uppercase;
             margin: 0 0 1.5em 0;

             @media (max-width: 767.98px) {
               margin-bottom: 60px;
             }
           }

           h4 {
             font-weight: 900;
             text-align: center;
             margin-bottom: 2em;
           }

           .details {
             background: #fff;
             border-radius: 8px;
             padding: 60px;
             color: ${constants.contentTextColor};
             justify-content: space-evenly;

             @media (max-width: 767.98px) {
               padding: 24px 21px;
             }

             .framing {
               font-size: 21px;
               line-height: 31px;

               .intro {
                 margin-bottom: 2em;
               }

               p {
                 margin-bottom: 1em;
                 font-size: 24px;
                 line-height: 36px;
               }

               h5 {
                 margin-bottom: 1em;
               }
             }

             .contacts {
               ul {
                 list-style: none;
                 margin: 0;
                 padding: 0;

                 li {
                   border-bottom: 3px solid ${constants.tanColor};
                   padding: 2rem 0;

                   &:first-of-type {
                     border-top: 3px solid ${constants.tanColor};
                   }

                   h4 {
                     text-align: left;
                     margin-bottom: 0.25em;
                   }

                   h5 {
                     margin-bottom: 1.5em;
                   }

                   .buttons {
                     display: flex;

                     > * {
                       margin-right: 1rem;
                       flex: 1;

                       &:last-child {
                         margin-right: 0;
                       }
                     }
                   }
                 }
               }
             }
           }
         }

         .call-legislators {
           max-width: 1184px;
           margin: 60px auto;

           @media (max-width: 1184px) {
             padding: 0 1rem;
           }

           .content {
             background: #fff;
             border-radius: 8px;
             padding: 60px;
             color: ${constants.contentTextColor};
             justify-content: space-evenly;

             @media (max-width: 767.98px) {
               padding: 24px 21px;
             }
           }

           #campaign-zero-widget {
             display: block;
             width: 100%;
           }
         }
       `;

export default StatePage;
